<template>
  <div class="relative w-12 h-12 flex flex-wrap container" :class="getToCenter ? 'content-center container-center' : 'content-start'">
    <div class="hidden md:flex flex justify-center items-center cursor-pointer text-base font-blackMango relative" id="localeBossDiv">
      <inline-svg class="relative" width="1.9rem" height="1.9rem" :fill="fillColor" src="/svg/language.svg" /><p :class="fillColor == 'black' ? 'uppercase' : 'uppercase text-'+fillColor"  >{{ $i18n.locale }}</p>
      <div class="hidden" id="localeChildDiv" :class="verticalDrop && 'verticalDrop'" >
        <ul class="uppercase tracking-widest">
          <li class="cursor-pointer" v-for="locale in localeOptions" :key="locale" @click="switchLocale(locale)">
            {{locale}}
          </li>
        </ul>
      </div>
    </div>

    <!-- MOBILE -->
    <!-- <div @click="showLanguages" class="sm:hidden flex justify-center items-center cursor-pointer text-base font-blackMango" id="localeBossDiv">
      <inline-svg width="1rem" height="1rem" :fill="fillColor" src="/svg/language.svg" /><p :class="fillColor == 'black' ? 'uppercase' : 'uppercase text-'+fillColor">{{ $i18n.locale }}</p>
    </div>
    <div class="sm:hidden mt-8 absolute rounded-b-lg w-12 h-12" v-show="languages" id="localeChildDiv">
      <ul class="items-center uppercase tracking-widest ml-6">
        <li class="cursor-pointer hover:text-bronze text-sm" v-for="locale in localeOptions" :key="locale" @click="switchLocale(locale)">
          {{locale}}
        </li>
      </ul>
    </div> -->
    <!-- END MOBILE -->
  </div>
</template>
<style scoped>
.container{
  margin-top: -6px;
}
.container-center {
  margin-top:0
}
#localeChildDiv{
  padding: 0;
  margin:0;
  margin-left:1rem;
  display: inline-block;
  visibility: hidden;
}
#localeChildDiv.verticalDrop{
  position: absolute;
  top: 2rem;
  width: 100%;
  margin:0;
  text-align: end;
}
#localeChildDiv ul {
  margin:0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  color:white;
}
#localeChildDiv.verticalDrop ul {
  flex-direction: column;
  color: black;
}
#localeChildDiv.verticalDrop li{
  width: 100%;
}
.container-center ul li {
  color: black;
  display: inline-block;
}
.container #localeChildDiv ul li:hover {
  color: var(--color-bronze);
}
#localeChildDiv ul li:nth-child(2n) {
  margin-left: 1rem;
}
#localeChildDiv.verticalDrop ul li:nth-child(2n) {
  margin-top: 1rem;
}
#localeBossDiv:hover #localeChildDiv {
  visibility: visible;
}
#localeChildDiv.verticalDrop:hover {
  visibility: visible;
}
</style>
<script>
import * as __COOKIES from '@/store/cookies/cookies'

export default {
  name: 'LocaleSwitcher',
  props:{
    fillColor:{
      type: String,
      default: 'black'
    },
    toCenter:{
      type: Boolean,
      default: false
    },
    verticalDrop: {
      type: Boolean,
      default: false
    }
  },
  created(){
    // Caso o user ja tenha mudado a lingua uma vez, entao vamos ao cookie e atualizamos
    let lng = this.$cookies.get(__COOKIES.LANGUAGE_SAVER)
    
    if(lng != 'en'){
        if(lng != null)
          this.switchLocale(lng)
    }
  },
  computed:  {
    getToCenter(){
      return this.toCenter;
    },
    localeOptions(){
      return this.locales.filter(l => l !== this.$i18n.locale);
    }

  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: {locale} }) 
        this.$router.push(to.location) 
        this.languages = !this.languages;
        this.$cookies.set(__COOKIES.LANGUAGE_SAVER, locale, '1y')
      }
    },
    showLanguages(){
      this.languages = !this.languages
    }
  },
  data() {
    return {
      languages: false,
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
    }
  }
}
</script>