<template>
  <div @click="goSearch" id="searchBar" class="relative w-12 h-12 flex flex-wrap container content-start">
    <inline-svg  v-if="getColor == 'black'" class="relative mt-3 hidden md:block" width="1.9rem" height="1.9rem" src="/svg/search_black.svg" />
    <inline-svg  v-else class="relative hidden md:block" width="1.9rem" height="1.9rem" src="/svg/search_white.svg" />
    <div v-if="false" class="md:hidden" id="searchInput">
        <div class="flex bg-white rounded-lg h-5 lg:h-8 w-full relative items-center border border-gray lg:border-0">
            <inline-svg class="hidden md:block absolute inset-y-0 my-auto left-1" height="1.5rem" width="1.5rem"
                src="/svg/search.svg" />
            <inline-svg class="md:hidden absolute inset-y-0 my-auto left-1" height="1rem" width="1rem"
                src="/svg/search.svg" />

            <gmap-autocomplete 
                @place_changed="setPlace"
                ref="myGmapAutocomplete"
                id="myGmapAutocomplete"
                :options='{
                        componentRestrictions: { country: "pt" },
                        fields: ["address_components", "geometry", "icon", "name"],
                        strictBounds: false,
                        types: ["geocode"],
                    }'
                :placeholder="$t('GOOGLE_MAP.search_placeholder')"
                class="bg-white appearance-none rounded-lg block py-2 h-full w-full pl-6 lg:pl-8 focus:ring-1 focus:ring-bronze">
            </gmap-autocomplete>
        </div>
      </div>
  </div>
</template>
<style scoped>
.container{
  margin-top: -4px;
}
#searchInput{
  width:24.2vw;
  position:absolute;
  top:3rem;
  height: 200px;
}
#searchBar:hover #searchInput{
  display:block;
}
#searchInput:hover{
  display:block !important;
}
#searchInput:focus-within {
    display:block !important;
}
@media (max-width:768px){
  #searchInput{
    width:90vw;
    position:fixed;
    top:3rem;
    left: 5vw;
    height: 200px;
  }
  #searchInput input{
    padding:5px;
    padding-left: 50px;
    font-size: calc(0.4rem + 0.55vw);
  }
}
</style>
<script>

export default {
  name: 'SearchBar',
  props:["color"],
  created(){
  },
  computed:  {
    getColor(){
      return this.color || "white";
    }
  },
  methods: {
    goSearch(){
      if(this.$i18n.locale == 'en'){
        window.location.href = this.$ego_redirecter() + '/en-gb/properties'
      }else{
        window.location.href = this.$ego_redirecter() + '/imoveis'
      }
    },
    setPlace(place) {
        sessionStorage.setItem('placeListing',JSON.stringify(place)); 
        this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: 'placeListing'}})
    }
  }
}
</script>